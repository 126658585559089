import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchInput = () => {
    const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchQuery) {
      const searchParams = new URLSearchParams();
      searchParams.append('query', searchQuery);
      navigate(`/search?${searchParams.toString()}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="flex justify-between items-center bg-[#f0eeee] border-2 border-[#851f81] rounded-full w-full md:w-[430px] h-[47px] px-4 my-2 md:my-0">
      <input
        id="search"
        type="text"
        placeholder={t('searchBar.searchPlaceholder')}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyPress}
        className="bg-transparent outline-none text-[#515151] w-full"
      />
      <button onClick={handleSearch} className="-mr-4 cursor-pointer">
        <img src="/svg/search.svg" alt="Search Icon" />
      </button>
    </div>
  );
};

export default SearchInput;
