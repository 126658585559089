import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaInstagram, FaInstagramSquare, FaLinkedin, FaWhatsapp, FaYoutube } from 'react-icons/fa';

const SocialMediaIcons = () => {
  const { t } = useTranslation();
  const socialLinks = {
    Facebook: "https://www.facebook.com/saveenergylife/",
    Instagram: "https://www.instagram.com/saveenergylife/",
    LinkedIn: "https://www.linkedin.com/company/save-energy-life/?originalSubdomain=br",
    Youtube: "https://www.youtube.com/channel/UCbgbD4pV_HXxlW_MflAk-6A",
  };

  return (
    <div className="flex flex-col items-center">
      <p className="text-sm md:text-xl font-medium text-[#890083] text-center mb-2">
        {t('socialMediaIcons.followUs')}
      </p>
      <div className="flex justify-center gap-8 mt-2">
        {Object.entries(socialLinks).map(([platform, url]) => (
          <a
            key={platform}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-900 hover:text-[#890083] transition"
            title={platform}
          >
            {platform === "Facebook" && <FaFacebook size={40} />}
            {platform === "Instagram" && <FaInstagram size={40} />}
            {platform === "LinkedIn" && <FaLinkedin size={40} />}
            {platform === "Youtube" && <FaYoutube size={40} />}
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaIcons;
