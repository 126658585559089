import React from 'react';
import ReactDOM from 'react-dom';
import RDStationForm from './RDStationForm';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-overlay') {
            onClose();
        }
    };

    return ReactDOM.createPortal(
        <div
            id="modal-overlay"
            onClick={handleOutsideClick}
            className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50"
        >
            <div className="bg-white rounded-lg shadow-lg md:p-6 relative max-h-screen overflow-y-auto">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-600 hover:text-purple-900 focus:outline-none z-50"
                >
                    &#x2715;
                </button>
                {children}
            </div>
        </div>,
        document.body
    );
};

const ArchitectFormModal = ({ isOpen, onClose }) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="flex items-center md:py-4">
                <RDStationForm />
            </div>
        </Modal>
    );
};

export default ArchitectFormModal;