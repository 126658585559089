import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import productsData from '../assets/Produtos.json';
import CategoriaCard from '../components/CategoriaCard';
import { useTranslation } from 'react-i18next';

const ProductsSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showAllCategories, setShowAllCategories] = useState(false);

  const handleCategoryClick = (categoryName) => {
    navigate(`/categoria/${categoryName}`);
  };

  // Extract categories only once
  const categories = React.useMemo(
    () => Array.from(new Set(productsData.map(product => product.Categoria))),
    []
  );

  const getFilePath = (category) => {
    const basePath = `/produtos-assets/${category}/${category}`;
    return [`${basePath}.webp`];
  };

  // Determine categories to display
  const displayedCategories = showAllCategories ? categories : categories.slice(0, 8);

  return (
    <div className="w-full flex flex-col justify-center items-center py-8 bg-gray-50">
      {/* Header */}
      <div className="w-full flex flex-col md:flex-row justify-center items-center mb-6 space-y-2 md:space-y-0">
        <div className="text-[#3c1053] text-lg md:text-2xl font-semibold mt-4">{t('productsSection.categoriesTitle')}</div>
      </div>
      <div className="w-28 border-2 border-[#3c1053] mb-8"></div>

      {/* Categories Grid */}
      <div className="w-full flex justify-center items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          {displayedCategories.map((category, index) => (
            <CategoriaCard
              key={index}
              product={{
                title: category,
                imgs: getFilePath(category),
              }}
              onClick={() => handleCategoryClick(category)}
            />
          ))}
        </div>
      </div>

      {/* Show More Button */}
      <div className="my-8">
        <button
          onClick={() => setShowAllCategories(!showAllCategories)}
          className="flex items-center justify-center px-6 py-3 border-2 border-[#3d1053] rounded-full text-[#3c1053] text-sm md:text-base font-medium hover:bg-[#3d1053] hover:text-white transition-all"
        >
          {showAllCategories ? t('productsSection.showLess') : t('productsSection.showMore')}
        </button>
      </div>
    </div>
  );
};

export default ProductsSection;
