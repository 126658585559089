import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import productsData from '../assets/Produtos.json';
import CategoriaCard from '../components/CategoriaCard';

const Produtos = () => {
    const navigate = useNavigate();

    const handleCategoryClick = (categoryName) => {
        navigate(`/categoria/${categoryName}`);
    };

    // Extract categories only once to prevent recalculation
    const categories = React.useMemo(() => (
        Array.from(new Set(productsData.map(product => product.Categoria)))
    ), [productsData]);

    const getFilePath = (category) => {
        const basePath = `/produtos-assets/${category}/${category}`;
        return [
            `${basePath}.webp`
        ];
    };

    return (
        <>
            <NavBar />
            <SearchBar />
            <div className="w-full flex flex-col justify-center items-center md:p-20 my-16 md:my-4">
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                    {categories.map((category, index) => (
                        <CategoriaCard
                            key={index}
                            product={{
                                title: category,
                                imgs: getFilePath(category)
                            }}
                            onClick={() => handleCategoryClick(category)}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Produtos;
