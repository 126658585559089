import React, { useState, useEffect, useMemo } from 'react';
import ProductIcons from '../components/Icones';

const ProdImageCard = ({ product }) => {
    const { SKU, Categoria, title, Nome } = product;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [displayImage, setDisplayImage] = useState('/media/default-image.webp');
    const [validImages, setValidImages] = useState([]);
    const [isFullImageVisible, setIsFullImageVisible] = useState(false);

    // Compute potential image paths once
    const potentialImgs = useMemo(() => {
        const suffixes = ["", "(1)", "(2)", "(3)", "(4)", "(5)"];
        return suffixes.flatMap((suffix) => [
            `/produtos-assets/${Categoria}/${SKU}/${SKU}${suffix}.webp`
        ]);
    }, [Categoria, SKU]);

    const potentialAmbientImgs = useMemo(() => {
        const suffixes = ["1", "2", "3", "4", "5"];
        return suffixes.flatMap((suffix) => [
            `/produtos-assets/${Categoria}/${SKU}/Ambientação/${suffix}.webp`
        ]);
    }, [Categoria, SKU]);

    useEffect(() => {
        const validateImages = async () => {
            const loadedImages = [];

            for (const imgPath of [...potentialImgs, ...potentialAmbientImgs]) {
                const img = new Image();
                img.src = imgPath;

                try {
                    await img.decode(); // If image loads, add to valid images
                    loadedImages.push(imgPath);
                } catch {
                    // Ignore invalid images
                }
            }

            if (loadedImages.length > 0) {
                setValidImages(loadedImages); // Set all valid images
                setDisplayImage(loadedImages[0]); // Display the first valid image
            }
        };

        validateImages();
    }, [potentialImgs, potentialAmbientImgs]);

    // Update display image when index changes
    useEffect(() => {
        if (validImages.length > 0) {
            setDisplayImage(validImages[currentImageIndex]);
        }
    }, [currentImageIndex, validImages]);

    // Navigate to the previous image
    const handlePrev = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex - 1 + validImages.length) % validImages.length
        );
    };

    // Navigate to the next image
    const handleNext = () => {
        setCurrentImageIndex(
            (prevIndex) => (prevIndex + 1) % validImages.length
        );
    };

    const handleImageClick = () => {
        setIsFullImageVisible(true);
    };

    const handleCloseFullImage = () => {
        setIsFullImageVisible(false);
    };

    return (
        <>
            <div
                className="bg-white rounded-[32px] shadow-md border border-neutral-300 h-[500px] md:h-[850px] flex items-center relative overflow-hidden"
            >
                {/* Display the current valid image */}
                <div className='flex flex-col items-center justify-center w-full h-full overflow-hidden'>
                    <div className="w-[600px] h-[600px] flex items-center justify-center overflow-hidden">
                        <img
                            className="object-cover rounded-[30px] cursor-pointer w-full h-full"
                            src={displayImage}
                            alt={title || Nome}
                            onClick={handleImageClick}
                        />
                    </div>
                    <div className='py-4'>
                        <hr className='w-full md:w-[500px] border border-gray-200 mb-2'/>
                        <ProductIcons product={product} />
                    </div>
                </div>
                {/* Navigation controls */}
                {validImages.length > 1 && (
                    <>
                        <button
                            className="flex justify-center items-center h-8 w-8 md:h-16 md:w-16 text-xl absolute left-2 top-1/2 transform -translate-y-1/2 border border-purple-900 hover:bg-purple-900 font-semibold text-purple-900 hover:text-white p-3 rounded-full"
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePrev();
                            }}
                        >
                            &#8592;
                        </button>
                        <button
                            className="flex justify-center items-center h-8 w-8 md:h-16 md:w-16 text-lg absolute right-2 top-1/2 transform -translate-y-1/2 border border-purple-900 hover:bg-purple-900 font-semibold text-purple-900 hover:text-white p-3 rounded-full"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleNext();
                            }}
                        >
                            &#8594;
                        </button>
                    </>
                )}
            </div>
            {/* Full image modal */}
            {isFullImageVisible && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                    onClick={handleCloseFullImage}
                >
                    <img
                        className="max-w-full max-h-full object-contain"
                        src={displayImage}
                        alt={title || Nome}
                    />
                    <button
                        className="absolute top-4 right-4 text-white text-3xl"
                        onClick={handleCloseFullImage}
                    >
                        &times;
                    </button>
                </div>
            )}
        </>
    );
};

export default ProdImageCard;
