import React, { useState } from 'react';
//import { signInWithEmailAndPassword } from 'firebase/auth';
//import { auth } from '../firebase';
//import { useAuth } from '../context/AuthContext';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    //const { login } = useAuth();

    const handleSubmit = async (e) => {
      //e.preventDefault();
      setError('');
    
      try {
        // Firebase login
        //await signInWithEmailAndPassword(auth, email, password);
        // Successful login, call login from AuthContext
        //login(); // Call the login function to set isAuthenticated to true
      } catch (err) {
        setError(err.message);
      }
    };

return (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <form
        onSubmit={handleSubmit}
        className="w-full md:w-1/3 bg-white md:shadow-md rounded-xl px-8 pt-6 pb-8 mb-4"
      >
        <img src="/logos/save-logo.png" className="flex justify-center items-center w-full mb-8 p-2" />
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-8">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Senha
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="w-full bg-purple-900 hover:bg-fuchsia-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Entrar
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;