const groupProductsByCategory = (products, family) => {
    const groupedProducts = {};

    const keywords = {
        Embutido: ["QUADRADO FACE RECUADA", "QUADRADO FACE PLANA", "REDONDO FACE RECUADA", "REDONDO FACE PLANA"],
        Plafon: ["QUADRADO FACE RECUADA", "QUADRADO FACE PLANA", "REDONDO FACE RECUADA", "REDONDO FACE PLANA"],
        Luminaria: ["QUADRADO FACE RECUADA", "QUADRADO FACE PLANA", "REDONDO FACE RECUADA", "REDONDO FACE PLANA", "Embutir", "Sobrepor"],
        FitaLED: [],
        Direcional: [],
        Decorativa: [],
        Geral: ["S2"],
        Downlight: ["Redondo", "Quadrado", "ANGULAR"],
        Acessorios: ["Slim"]
    };

    const groupBy = {
        Embutido: ["Nome", "Familia", "Base", "TamanhoProduto"],
        Plafon: ["Nome", "Familia"],
        Luminaria: ["Familia", "TamanhoProduto", "Nome", "TemperaturaCor"],
        FitaLED: ["Nome", "Familia", "TemperaturaCor", "Potencia"],
        Direcional: ["Nome", "Familia", "Tensao", "Potencia"],
        Decorativa: ["Nome", "Familia", "Tensao", "Potencia"],
        Geral: ["Nome"],
        Downlight: ["Nome", "Familia", "TemperaturaCor", "Cor"],
        Acessorios: ["Nome", "Familia", "Tensao", "Potencia"]
    };

    const applyKeywords = (name, family) => {
        const familyKeywords = keywords[family] || [];

        return familyKeywords.some(keyword => name.includes(keyword));
    };

    products.forEach(product => {
        if (product.Familia === family) {
            const groupKeys = groupBy[family] || [];
    
            // Compute the group key
            const groupKey = [
                product.SKU,
                ...groupKeys.map(key => {
                    if (key === "Nome" && applyKeywords(product[key], family)) {
                        return product[key];
                    }
                    return product[key];
                })
            ].join("|");
    
            if (!groupedProducts[groupKey]) {
                groupedProducts[groupKey] = [];
            }
    
            groupedProducts[groupKey].push(product);
            console.log(`Group Key: ${groupKey}`, product);

        }
    });

    return groupedProducts;
};

export default groupProductsByCategory;
