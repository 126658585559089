import { React, useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import SocialMediaIcons from '../components/socialMediaIcons';
import { FaArrowAltCircleLeft, FaBars, FaTimes } from 'react-icons/fa';
import ArchitectFormModal from '../components/ArchitectFormModal';

const CanalArquiteto = () => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dropdownRef = useRef(null);

  const links = {
    [t("canalArquiteto.links.3DModels")]: {
      [t("canalArquiteto.links.3DCasoca")]: "https://casoca.com.br/saveenergy.html",
      [t("canalArquiteto.links.3DWarehouse")]: "https://3dwarehouse.sketchup.com/org/911956a8-f654-4178-a573-c1bb590e1d3a/SAVEENERGY?hl=pt-BR#models",
    },
    [t("canalArquiteto.links.iesFiles")]: "https://drive.google.com/drive/folders/1YtJy5fMkEL2HfG0BXCx6xdIJT28Ev1Jn?usp=drive_link",
    [t("canalArquiteto.links.supportMaterial")]: "https://conteudo.save.com.br/material-apoio-canal-do-arquiteto",
    [t("canalArquiteto.links.whereToBuy")]: "https://conteudo.save.com.br/canal-do-arquiteto-onde-comprar",
    [t("canalArquiteto.links.partnership")]: "https://api.whatsapp.com/send?phone=5519994917709&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20Canal%20do%20arquiteto%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20de%20como%20funciona%20a%20parceria.%20",
    [t("canalArquiteto.links.submitProject")]: () => setModalOpen(true),
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative min-h-screen">
      <Helmet>
        <title>Canal do Arquiteto | Save Energy</title>
        <meta name="description" content="Aqui você tira todas as dúvidas sobre os nossos produtos com um de nossos especialistas. Solicite já o seu atendimento!" />
      </Helmet>
      {/* Background */}
      <div className="absolute inset-0 bg-white opacity-60 -z-10">
        <div className="absolute inset-0 bg-white opacity-50 -z-20"></div>
        <video autoPlay loop muted className="w-full h-full object-cover -z-20">
          <source src="/videos/bg-CA.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Back Button and Hamburger */}
      <div className="flex justify-between items-center px-4 py-2 z-50">
        <a href="/" className="text-white flex items-center gap-2 md:mt-4">
          <FaArrowAltCircleLeft />
          <span className="font-semibold text-sm">{t('canalArquiteto.back')}</span>
        </a>
        <button
          className="text-white text-2xl md:hidden"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="absolute top-16 left-0 w-full h-full bg-white bg-opacity-80 text-[#4a1a60] shadow-lg z-50">
          <ul className="flex flex-col gap-2 px-4 py-2">
            {Object.entries(links).map(([item, value]) => (
              <li key={item} className="w-full">
                {typeof value === "object" ? (
                  <div ref={dropdownRef} className="relative">
                    <button
                      onClick={toggleDropdown}
                      className="block text-sm font-medium bg-[#890083] hover:bg-[#4a1a60] text-white rounded-full py-2 px-4 shadow-md w-full"
                    >
                      {item}
                    </button>
                    {dropdownOpen && (
                      <ul className="absolute left-0 bg-white text-[#4a1a60] rounded-lg shadow-md mt-1 z-50 w-full">
                        {Object.entries(value).map(([subItem, url]) => (
                          <li key={subItem} className="hover:bg-[#f3f3f3]">
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="block px-4 py-2 text-sm"
                            >
                              {subItem}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <a
                    href={typeof value === "string" ? value : undefined}
                    target={typeof value === "string" ? "_blank" : undefined}
                    rel={typeof value === "string" ? "noopener noreferrer" : undefined}
                    onClick={typeof value === "function" ? value : undefined}
                    className="block text-sm font-medium bg-[#890083] hover:bg-[#4a1a60] text-white rounded-full py-2 px-4 shadow-md w-full"
                  >
                    {item}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Desktop Menu */}
      <div className="hidden md:flex justify-center items-center mt-4 w-full z-40">
        <ul className="md:w-2/3 flex flex-row gap-2">
          {Object.entries(links).map(([item, value]) => (
            <li key={item} className="w-full cursor-pointer">
              {typeof value === "object" ? (
                <div ref={dropdownRef} className="relative">
                  <button
                    onClick={toggleDropdown}
                    className="block text-center text-base font-medium bg-[#890083] hover:bg-[#4a1a60] text-white rounded-full py-2 shadow-md transition w-full"
                  >
                    {item}
                  </button>
                  {dropdownOpen && (
                    <ul className="absolute left-3 bg-white text-[#4a1a60] rounded-lg shadow-md mt-1 z-50">
                      {Object.entries(value).map(([subItem, url]) => (
                        <li key={subItem} className="hover:bg-[#f3f3f3]">
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block px-4 py-2 text-md"
                          >
                            {subItem}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <a
                  href={typeof value === "string" ? value : undefined}
                  target={typeof value === "string" ? "_blank" : undefined}
                  rel={typeof value === "string" ? "noopener noreferrer" : undefined}
                  onClick={typeof value === "function" ? value : undefined}
                  className="block text-center text-base font-medium bg-[#890083] hover:bg-[#4a1a60] text-white rounded-full py-2 shadow-md transition w-full"
                >
                  {item}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex flex-col items-center justify-center h-[80vh] text-center text-white px-4 z-40">
        <img
          src="/logos/canalArquiteto.png"
          className="w-[90%] md:w-[25%] mb-2"
          alt="Canal do Arquiteto Logo"
        />
        <div className="rounded-xl px-4 my-8 bg-[#3c1053] bg-opacity-80 z-40 max-w-lg md:max-w-3xl py-2">
          <p className="text-sm md:text-base font-light">
            {t('canalArquiteto.exclusiveText')}
          </p>
        </div>
        <div className="drop-shadow-md">
          <SocialMediaIcons />
        </div>
      </div>

      {/* Architect Form Modal */}
      <ArchitectFormModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};

export default CanalArquiteto;
