// src/context/ProductContext.js
import React, { createContext, useContext, useMemo } from 'react';
import productsData from '../assets/Produtos.json';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
    // Generate possible image paths based on SKU and category
    const getFilePath = (product) => {
        const basePath = `/produtos-assets/${product.Categoria}/${product.SKU}/${product.SKU}`;
        const suffixes = ["", "-1", "_1", "(1)"];
        return suffixes.flatMap(suffix => [
            `${basePath}${suffix}.webp`
        ]);
    };

    // Extract categories only once
    const categories = useMemo(() => (
        Array.from(new Set(productsData.map(product => product.Categoria)))
    ), []);

    const getCategoryProducts = (categoryName) => (
        productsData.filter(product => product.Categoria === categoryName)
    );

    return (
        <ProductContext.Provider value={{ productsData, getFilePath, categories, getCategoryProducts }}>
            {children}
        </ProductContext.Provider>
    );
};

// Custom hook for easier context access
export const useProductContext = () => useContext(ProductContext);
