import React, { useEffect } from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import TabelaLuceLineare from '../components/TabelaLuceLineare'

function LuceLineare() {

  return (
    <div>
      <NavBar />
      <img 
  src="/media/LuceLineare/Perfis2024banner.webp" 
  className="w-full h-[50vh] object-right object-cover md:h-auto md:object-contain" 
  alt="Luce Lineare" 
/>

<div className="bg-[#3d1053] text-white text-center py-4 md:text-2xl md:py-8">
  <h2 className="tracking-widest" >UMA COLEÇÃO COMPLETA COM QUALIDADE SAVEENERGY</h2>
</div>

<video 
  className="w-full h-[50vh] object-cover object-center md:h-auto md:object-contain" 
  autoPlay 
  loop 
  muted
>
  <source src="/media/LuceLineare/LuceLineare_Video.webm" type="video/mp4" />
  Your browser does not support the video tag.
</video>


      {/* Profiles Images Section */}
      <TabelaLuceLineare />

      {/* Alumínio Section */}
<div className="relative text-white bg-neutral-800">
  <img
    src="/media/LuceLineare/LuceLineare_MaterialSite-03.webp"
    className="w-full h-screen object-cover opacity-70"
    alt="Caracteristicas Luce Lineare"
  />
  <div className="absolute inset-0 flex flex-col justify-center items-end text-center px-4 mr-8 mb-8 md:mb-12 lg:mb-16 min-h-screen">
    <ul className="list-disc list-inside space-y-4 md:space-y-8 lg:space-y-12 xl:space-y-16 text-left text-sm md:text-lg lg:text-xl">
      <h2 className="text-lg md:text-xl lg:text-2xl font-bold">
        Confeccionado em alumínio de liga 6063:
      </h2>
      <li><strong className="font-bold">Alta resistência à corrosão</strong></li>
      <li><strong className="font-bold">Boa usinabilidade:</strong> fácil de cortar e perfurar, permitindo versatilidade no design.</li>
      <li><strong className="font-bold">Excelente acabamento superficial,</strong> com pintura eletrostática.</li>
      <li><strong className="font-bold">Boa condutividade térmica e elétrica:</strong> dissipação de calor e condutividade.</li>
      <li><strong className="font-bold">Durabilidade:</strong> Longa vida útil, mesmo em condições adversas.</li>
    </ul>
  </div>
</div>

{/* Difusor Section */}
<div className="relative text-gray-800">
  <img
    src="/media/LuceLineare/LuceLineare_MaterialSite-04.webp"
    className="w-full h-screen object-cover"
    alt="Caracteristicas Luce Lineare"
  />
  <div className="absolute inset-0 flex w-full md:w-3/4 lg:w-1/2 flex-col justify-center text-center px-4 md:px-8 lg:px-12 pb-8 md:pb-12 lg:pb-16 py-4 lg:py-12 ml-4 md:ml-8 lg:ml-12 max-w-full overflow-hidden min-h-screen">
    <ul className="list-disc list-inside space-y-4 md:space-y-8 lg:space-y-12 xl:space-y-16 text-left text-sm md:text-lg lg:text-xl mt-4 md:mt-8 lg:mt-12">
      <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-[#3d1053]">
        Difusor: Inovação e Durabilidade com Tecnologia PROSHIELD UV.
      </h2>
      <li>Nossos perfis contam com um difusor equipado com a exclusiva <strong className="font-bold">tecnologia PROSHIELD UV</strong></li>
      <li><strong className="font-bold">Resistência superior: </strong>O policarbonato é reconhecido por sua incrível resistência a impactos, garantindo um produto robusto e confiável.</li>
      <li><strong className="font-bold">Proteção contra amarelamento: </strong> A tecnologia <strong className="font-bold">PROSHIELD UV</strong> forma uma barreira protetora contra os raios ultravioleta, prevenindo o amarelamento e a degradação.</li>
      <li><strong className="font-bold">Iluminação homogênea: </strong> Design desenvolvido para proporcionar uma distribuição de luz perfeita, reduzindo pontos de ofuscamento e garantindo um ambiente mais confortável e sofisticado.</li>
      <li><strong className="font-bold">Longevidade e estética impecável:</strong> Com alta resistência a variações de temperatura e exposição contínua à luz, nossos perfis preservam sua estética e desempenho ao longo do tempo.</li>
      <div className="flex flex-row justify-start items-center gap-4 md:gap-6 lg:gap-8">
        <img src="/media/LuceLineare/proshieldIcon.png" alt="Proshield UV Icon" />
        <p className="my-auto font-bold w-[80%] text-[#3d1053]">
          A tecnologia por trás da coleção Luce Lineare é o reflexo do nosso compromisso com a excelência e inovação.
        </p>
      </div>
    </ul>
  </div>
</div>


      <Footer />
    </div>
  )
}

export default LuceLineare