import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../Context/ProductContext';
import { useTranslation } from 'react-i18next';

const SearchBar = () => {
  const { t, i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(t('searchBar.allProducts'));
  const [searchQuery, setSearchQuery] = useState('');
  const [familyOptions, setFamilyOptions] = useState([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { productsData, getCategoryProducts } = useProductContext();

  const categories = [t('searchBar.allProducts'), ...new Set(productsData.map(product => product.Categoria))];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Update selectedCategory when language changes
    setSelectedCategory(t('searchBar.allProducts'));
  }, [i18n.language, t]);

  useEffect(() => {
    if (selectedCategory === t('searchBar.allProducts')) {
      setFamilyOptions([]);
    } else {
      const categoryProducts = getCategoryProducts(selectedCategory);
      const families = ['Todos', ...new Set(categoryProducts.map(product => product.Familia))];
      setFamilyOptions(families);
    }
  }, [selectedCategory, getCategoryProducts]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSearchQuery('');
    setIsDropdownOpen(false);
  };

  const handleSearch = () => {
    const searchParams = new URLSearchParams();
    if (selectedCategory !== t('searchBar.allProducts')) {
      searchParams.append('category', selectedCategory);
    }
    if (searchQuery) {
      searchParams.append('query', searchQuery);
    }
    navigate(`/search?${searchParams.toString()}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="w-full h-auto relative bg-[#3c1053] z-30">
      {/* Title */}
      <div className="w-full py-4 md:py-8 text-center text-white text-xl md:text-4xl font-bold">
        {t('searchBar.title')}
      </div>

      {/* Search Bar */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-4 px-4 md:px-0 pb-8">
        {/* Dropdown */}
        <div className="flex flex-col justify-center items-center relative w-full md:w-auto" ref={dropdownRef}>
          <div
            className="flex items-center justify-between bg-[#890083] px-4 py-3 w-full md:w-[285px] h-[40px] md:h-[47px] rounded-full text-white font-medium cursor-pointer"
            onClick={toggleDropdown}
          >
            <span className="w-full text-center truncate">{selectedCategory}</span>
            <img src='/svg/arrow-down.svg' className='absolute right-4 h-5' alt="Dropdown Icon" />
          </div>

          {isDropdownOpen && (
            <div className="absolute bg-white rounded-lg w-full md:w-[285px] translate-y-[55%] z-10">
              {categories.map((category) => (
                <div
                  key={category}
                  className="px-4 py-2 hover:bg-gray-100 text-gray-800 cursor-pointer truncate"
                  onClick={() => handleCategorySelect(category)}
                >
                  {category}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Search Input */}
        <div className="flex justify-between items-center bg-[#f0eeee] border-2 border-[#851f81] rounded-full w-full md:w-[430px] h-[47px] px-4">
          <input
            id="search"
            type="text"
            placeholder={t('searchBar.searchPlaceholder')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyPress}
            className="bg-transparent outline-none text-[#515151] w-full"
          />
          <button onClick={handleSearch} className="-mr-4 cursor-pointer">
            <img src="/svg/search.svg" alt="Search Icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
