import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";
import Home from './pages/Home'
import SearchResults from './pages/SearchResults';
import Produtos from './pages/Produtos';
import Institucional from './pages/Institucional';
import Suporte from './pages/Suporte';
import ProdutoIndividual from './pages/ProdutoIndividual';
import Categoria from './pages/Categoria';
import { ProductProvider } from './Context/ProductContext';
import TermosCondicoes from './pages/TermosCondicoes';
import Catalogo from './pages/Catalogo';
import CanalArquiteto from './pages/CanalArquiteto';
import Login from './pages/Login';
import LuceLineare from './pages/LuceLineare';

function App() {
  return (
    <HelmetProvider>
    <ProductProvider>
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/produtos" element={<Produtos />} />
          <Route path="/catalogo" element={<Catalogo />} />
          <Route path="/categoria/:categoryName" element={<Categoria />} />
          <Route path="/produtos/:categoryName/:productId" element={<ProdutoIndividual />} />
          <Route path="/institucional" element={<Institucional />} />
          <Route path="/suporte" element={<Suporte />} />
          <Route path="/canaldoarquiteto" element={<CanalArquiteto />} />
          <Route path="/termoscondicoes" element={<TermosCondicoes />} />
          <Route path="/lucelineare" element={<LuceLineare />} />
        </Routes>
    </Router>
    </ProductProvider>
    </HelmetProvider>
  );
}

export default App
