import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ProdutoCard from '../components/ProdutoCard';
import { useProductContext } from '../Context/ProductContext';
import SearchInput from '../components/SearchInput';

const ITEMS_PER_PAGE = 16;

const Categoria = () => {
    const { categoryName } = useParams();
    const navigate = useNavigate();
    const { getCategoryProducts, getFilePath } = useProductContext();

    const allProducts = getCategoryProducts(categoryName);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFamily, setSelectedFamily] = useState('Todos');

    const productFamilies = ['Todos', ...new Set(allProducts.map(product => product.Familia))];
    const filteredProducts = selectedFamily === 'Todos'
        ? allProducts
        : allProducts.filter(product => product.Familia === selectedFamily);

    const totalPages = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentProducts = filteredProducts.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const handleProductClick = (SKU) => {
        navigate(`/produtos/${categoryName}/${SKU}`);
    };

    const handleFamilyChange = (event) => {
        setSelectedFamily(event.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>{categoryName ? `${categoryName} | SAVEENERGY` : "Categorias | SAVEENERGY"}</title>
                <meta name="description" content={`Explore nossa linha de produtos da categoria ${categoryName}. Iluminação inovadora e sustentável para todos os ambientes.`} />
                <meta property="og:title" content={categoryName ? `${categoryName} | SAVEENERGY` : "Categorias | SAVEENERGY"} />
                <meta property="og:description" content={`Confira os produtos da categoria ${categoryName} e encontre a melhor solução em iluminação LED.`} />
                <meta property="og:image" content={currentProducts.length > 0 ? getFilePath(currentProducts[0])[0] : "/default-category.jpg"} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:title" content={categoryName ? `${categoryName} | SAVEENERGY` : "Categorias | SAVEENERGY"} />
                <meta name="twitter:description" content={`Confira os produtos da categoria ${categoryName} e encontre a melhor solução em iluminação LED.`} />
                <meta name="twitter:image" content={currentProducts.length > 0 ? getFilePath(currentProducts[0])[0] : "/default-category.jpg"} />
            </Helmet>
            <NavBar />
            <span className="flex flex-col md:flex-row justify-between items-center py-4 px-4 md:px-20 text-lg font-semibold text-white bg-[#3c1053]">
                <div>
                    <Link to="/produtos" className="hover:underline">
                        Produtos
                    </Link>
                    <span className="mx-1">/</span>
                    <span>{categoryName}</span>
                </div>
                <SearchInput />
            </span>

            <div className="w-full flex flex-col justify-center items-center mb-16">
                <h1 className="py-8 font-bold text-[#3c1053]">{categoryName}</h1>

                {/* Family Filter Dropdown */}
                <div className="w-full max-w-[1200px] flex justify-center md:justify-end items-center px-4 mb-4">
                    <label className="text-[#3c1053] font-semibold mr-2 hidden md:block">Filtrar por:</label>
                    <select
                        value={selectedFamily}
                        onChange={handleFamilyChange}
                        className="px-4 py-2 border rounded-md bg-white text-[#3c1053] focus:outline-none"
                    >
                        {productFamilies.map((family) => (
                            <option key={family} value={family}>
                                {family}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Display Products */}
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 w-full max-w-[1200px] px-4">
                    {currentProducts.map((product) => {
                        const ambientPath = `/produtos-assets/${categoryName}/${product.SKU}/Ambientação/1.webp`;
                        return (
                            <ProdutoCard
                                key={product.SKU}
                                product={{
                                    ...product,
                                    imgs: getFilePath(product),
                                    ambientImagePath: ambientPath,
                                }}
                                onClick={() => handleProductClick(product.SKU)}
                            />
                        );
                    })}
                </div>

                {/* Pagination Controls */}
                <div className="flex justify-center items-center mt-8 space-x-2">
                    <button
                        className="px-4 py-2 bg-gray-300 rounded"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <button
                            key={i + 1}
                            className={`hidden md:block px-4 py-2 rounded ${currentPage === i + 1 ? 'bg-purple-900 text-white' : 'bg-gray-300'}`}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                    <button
                        className="px-4 py-2 bg-gray-300 rounded"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Próximo
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Categoria;
