import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../i18n';
//import { FaUserCircle } from 'react-icons/fa';
//import Login from '../pages/Login';

const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    //const [loginModalState, setLoginModalState] = useState(false);

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div
            className="w-full h-[100px] bg-slate-50 shadow-md flex items-center justify-between px-6 md:px-20"
            style={{ zIndex: 50 }} // Ensure the navbar is always in front
        >
            {/* Logo */}
            <div id="logo">
                <a href="/">
                    <img className="" src="/logos/logo-save.png" alt="saveenergy" />
                </a>
            </div>

            {/* Hamburger Menu Icon (Mobile Only) */}
            <div
                className="md:hidden cursor-pointer"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
                <div className="w-6 h-0.5 bg-[#3d1053] mb-1"></div>
                <div className="w-6 h-0.5 bg-[#3d1053] mb-1"></div>
                <div className="w-6 h-0.5 bg-[#3d1053]"></div>
            </div>

            {/* Navigation Links */}
            <div
                id="nav"
                className={`absolute top-[100px] left-0 w-full bg-slate-50 shadow-md flex flex-col items-center space-y-6 py-6 md:py-0 md:space-y-0 md:flex-row md:static md:w-auto md:space-x-8 md:shadow-none transition-all ${isMenuOpen ? 'block' : 'hidden'
                    } md:block`}
                style={{ zIndex: 40 }} // Ensure menu appears above other components
            >
                <a href="/produtos" className="font-semibold text-[#3d1053]">
                    {t('nav.products')}
                </a>
                <a
                    href="/catalogo"
                    className="font-semibold text-[#3d1053]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('nav.catalog')}
                </a>
                <a href="/institucional" className="font-semibold text-[#3d1053]">
                    {t('nav.institutional')}
                </a>
                <a
                    href="/canaldoarquiteto"
                    className="font-semibold text-[#3d1053]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('nav.architectChannel')}
                </a>
                <a
                    href="https://save.com.br/blog/"
                    className="font-semibold text-[#3d1053]"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('nav.blog')}
                </a>
                <a href="/suporte" className="font-semibold text-[#3d1053]">
                    {t('nav.support')}
                </a>
            </div>

            {/* Social Links & Language Selector */}
            <div className="hidden md:flex items-center space-x-4">
                <a
                    href="https://www.facebook.com/saveenergylife/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/media/icones_social/facebook.svg"
                        alt="Facebook"
                        className="w-6 h-6 hover:scale-110 transition-transform duration-200"
                    />
                </a>
                <a
                    href="https://www.instagram.com/saveenergylife/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/media/icones_social/instagram.svg"
                        alt="Instagram"
                        className="w-6 h-6 hover:scale-110 transition-transform duration-200"
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/save-energy-life/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        src="/media/icones_social/linkedin.svg"
                        alt="LinkedIn"
                        className="w-6 h-6 hover:scale-110 transition-transform duration-200"
                    />
                </a>
                <div className="flex items-center">
                    <button onClick={() => changeLanguage('pt')} className={`font-bold cursor-pointer ${i18n.language === 'pt' ? 'text-[#3c1053]' : 'text-neutral-500'}`}>
                        PT
                    </button>
                    <span className="text-neutral-500 font-semibold">|</span>
                    <button onClick={() => changeLanguage('en')} className={`font-bold cursor-pointer ${i18n.language === 'en' ? 'text-[#3c1053]' : 'text-neutral-500'}`}>
                        EN
                    </button>
                </div>
                {/*<button
                    onClick={() =>
                        setLoginModalState(true)}
                    className="cursor-pointer pl-2 z-100"
                >
                    <FaUserCircle className='text-purple-900 w-8 h-8' />
                </button>*/}
            </div>
            {/*{loginModalState && <Login onClose={() => setLoginModalState(false)} />}*/}
        </div>
    );
};

export default NavBar;
