import React, { useEffect } from "react";

const RDStationForm = () => {
  useEffect(() => {
    const scriptId = "rdstation-forms-script";

    // Check if the script is already added to the document
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
      script.async = true;
      script.onload = () => {
        if (window.RDStationForms) {
          new window.RDStationForms("projeto-no-insta-b38ab8adf0f9d5d48eaa", "UA-169207827-1").createForm();
        }
      };
      document.body.appendChild(script);
    } else {
      // If the script is already loaded, create the form directly
      if (window.RDStationForms) {
        new window.RDStationForms("projeto-no-insta-b38ab8adf0f9d5d48eaa", "UA-169207827-1").createForm();
      }
    }
  }, []);

  return <div id="projeto-no-insta-b38ab8adf0f9d5d48eaa"></div>;
};

export default RDStationForm;
