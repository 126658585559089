import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const VideoSection = () => {
    const { t } = useTranslation();
    const player1Ref = useRef(null);
    const player2Ref = useRef(null);
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!isVisible) return;

        // Load YouTube IFrame Player API
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        script.async = true;
        document.body.appendChild(script);

        // Initialize players once API is ready
        window.onYouTubeIframeAPIReady = () => {
            // Video 1
            player1Ref.current = new window.YT.Player('player1', {
                videoId: '0vmSrQAyqdo', // Replace with your video ID
                playerVars: {
                    autoplay: 0,
                    controls: 1,
                    rel: 0, // Prevent showing related videos
                    showinfo: 0,
                },
                events: {
                    onStateChange: (event) => console.log('Player 1 State:', event.data),
                },
            });

            // Video 2
            player2Ref.current = new window.YT.Player('player2', {
                videoId: 'fAz8_R40WkA', // Replace with your video ID
                playerVars: {
                    autoplay: 0,
                    controls: 1,
                    rel: 0,
                    showinfo: 0,
                },
                events: {
                    onStateChange: (event) => console.log('Player 2 State:', event.data),
                },
            });
        };

        return () => {
            // Clean up script
            document.body.removeChild(script);
        };
    }, [isVisible]);

    return (
        <div ref={sectionRef} className="w-full h-auto flex flex-col justify-center items-center bg-[#3c1053] text-white py-12">
            {/* Container Videos */}
            <div className="w-full flex flex-col md:flex-row justify-center items-center gap-8 px-4 md:px-8">
                {/* Video 1 */}
                <div className="relative w-full md:w-1/2 aspect-w-16 aspect-h-9 bg-gradient-to-b from-neutral-300 to-[#353535] rounded-[32px] shadow-lg overflow-hidden">
                    <div id="player1" className="w-full h-full rounded-[30px]"></div>
                </div>

                {/* Video 2 */}
                <div className="relative w-full md:w-1/2 aspect-w-16 aspect-h-9 bg-gradient-to-b from-neutral-300 to-[#353535] rounded-[32px] shadow-lg overflow-hidden">
                    <div id="player2" className="w-full h-full rounded-[30px]"></div>
                </div>
            </div>

            {/* Botão Mais Videos */}
            <div className="mt-8">
                <a
                    href="https://www.youtube.com/@SaveEnergyLightingLifeStyle"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Veja mais vídeos no YouTube"
                    className="flex items-center justify-center px-6 md:px-8 py-2 md:py-3 border-2 border-white rounded-full text-white text-sm md:text-base font-medium transition-all hover:bg-white hover:text-[#3c1053]"
                >
                    {t('videos.watchMore')}
                </a>
            </div>
        </div>
    );
};

export default VideoSection;