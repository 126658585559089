import React from 'react';

const Footer = () => {
    return (
        <div className="flex flex-col w-full bg-[#3c1053] px-4 md:px-20 pb-2 md:py-8">
            {/* Main Section */}
            <div className="flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0 m-8">
                {/* Logo */}
                <div className="flex justify-center md:justify-start">
                    <img className="h-[80px] md:h-[121px]" src="/logos/logo-save-footer.png" alt="SaveEnergy Logo" />
                </div>

                {/* Contact Info */}
                <div className="text-center">
                    <span className="text-white text-md md:text-[28px] font-bold block">0800 750 5592</span>
                    <span className="text-white text-xs md:text-xl font-light">
                        sac@save.com.br<br />
                        vendas@saveenergylife.com
                    </span>
                </div>

                {/* Made in Brazil */}
                <div className="flex justify-center md:justify-end">
                    <img className="h-[60px] md:h-[90px]" src="/logos/feito-no-brasil.png" alt="Feito no Brasil" />
                </div>
            </div>

            {/* Address Section */}
            <div className="text-center text-white text-xs font-light px-4">
                Light Engine Iluminação S.A. | Av. Itaipava, 1097, Armazém 08, Sala A - CEP: 88.316-300 - Bairro Itaipava | Itajaí - SC | CNPJ 24.546.165/0001-21
            </div>
        </div>
    );
};

export default Footer;
