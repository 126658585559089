// src/pages/SearchResults.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ProdutoCard from '../components/ProdutoCard';
import { useProductContext } from '../Context/ProductContext';
import NavBar from '../components/NavBar';
import SearchBar from '../components/SearchBar';

const ITEMS_PER_PAGE = 16;

const SearchResults = () => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const { productsData, getCategoryProducts, getFilePath } = useProductContext();
    const navigate = useNavigate();

    // Parse query parameters
    const queryParams = new URLSearchParams(search);
    const category = queryParams.get('category') || 'Todos os Produtos';
    const query = queryParams.get('query')?.toLowerCase() || '';

    // Filter products based on category and search query across multiple fields including SKU
    const products = category === 'Todos os Produtos'
        ? productsData
        : getCategoryProducts(category);

    const filteredProducts = products.filter(product =>
        product.Nome.toLowerCase().includes(query) ||
        (product.SKU && product.SKU.toLowerCase().includes(query)) ||
        (product.Descricao && product.Descricao.toLowerCase().includes(query)) ||
        (product.Familia && product.Familia.toLowerCase().includes(query)) ||
        (product.Categoria && product.Categoria.toLowerCase().includes(query)) ||
        (product.Sinonimos && product.Sinonimos.toLowerCase().includes(query))
    );

    // Set up pagination
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE);

    // Calculate products to display on the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentProducts = filteredProducts.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    // Handle product click
    const handleProductClick = (SKU) => {
        navigate(`/produtos/${category}/${SKU}`);
    };

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <NavBar />
            <SearchBar />
            <div className="w-full flex flex-col justify-center items-center mb-16">
                <h1 className='py-8 font-regular text-gray-600 text-[20px]'>
                    {t('searchResults.results')} <span className='text-purple-900 font-bold'>{query}</span>
                </h1>

                {currentProducts.length > 0 ? (
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 w-full max-w-[1200px] px-4">
                        {currentProducts.map((product) => (
                            <ProdutoCard
                                key={product.SKU}
                                product={{
                                    ...product,
                                    imgs: getFilePath(product),
                                }}
                                onClick={() => handleProductClick(product.SKU)}
                            />
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-500">{t('searchResults.noResults')} "{query}".</p>
                )}

                {/* Pagination Controls */}
                {totalPages > 1 && (
                    <div className="flex justify-center items-center mt-8 space-x-2">
                        <button
                            className="px-4 py-2 bg-gray-300 rounded"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            {t('searchResults.previous')}
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button
                                key={i + 1}
                                className={`px-4 py-2 rounded ${currentPage === i + 1 ? 'bg-purple-900 text-white' : 'bg-gray-300'}`}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </button>
                        ))}
                        <button
                            className="px-4 py-2 bg-gray-300 rounded"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            {t('searchResults.next')}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default SearchResults;
