import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ProdImageCard from '../components/ProdImageCard';
import ProdutoCard from '../components/ProdutoCard';
import { useProductContext } from '../Context/ProductContext';
import { FaDownload, FaEye } from 'react-icons/fa';
import SearchInput from '../components/SearchInput';
import groupProductsByCategory from '../utils/groupProducts';

const ProdutoDetalhes = () => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const { productsData, getFilePath } = useProductContext();

    const [availableFiles, setAvailableFiles] = useState([]);
    const [activeTab, setActiveTab] = useState(productId); // Initialize with productId
    const [groupedProducts, setGroupedProducts] = useState({});

    // Find the current product by SKU
    const product = productsData.find(prod => prod["SKU"] === productId);
    if (!product) {
        return <div>Produto não encontrado</div>;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setActiveTab(product.SKU);

        // Group products by category
        const grouped = groupProductsByCategory(productsData, product.Familia);
        setGroupedProducts(grouped);
    }, [product.SKU, productsData, product.Familia]);

    // Generate image paths for the main product
    const productImages = getFilePath(product);

    const suggestedProducts = productsData
        .filter(p => p.Familia === product.Familia && p.SKU !== product.SKU)
        .slice(4, 8); // Limit to 4 suggested products

    // Handle product click to navigate to the respective detail page
    const handleProductClick = (SKU) => {
        navigate(`/produtos/${product.Categoria}/${SKU}`);
    };

    // Predefined file paths
    const getProductFiles = (product) => [
        { name: 'Folha de Apresentação', link: `/produtos-assets/${product.Categoria}/${product.SKU}/Apresentação.pdf` },
        { name: 'Manual', link: `/produtos-assets/${product.Categoria}/${product.SKU}/Manual.pdf` },
        { name: 'IES', link: `/produtos-assets/${product.Categoria}/${product.SKU}/${product.SKU}.IES` },
        { name: 'Bloco 3D', link: `${product.Bloco3D}` },
        { name: 'Gabarito', link: `/produtos-assets/${product.Categoria}/${product.SKU}/Gabarito.pdf` },
    ];

    const isExternalLink = (url) => {
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.origin !== window.location.origin; // External if origins differ
        } catch {
            return false; // Not a valid URL
        }
    };

    const checkFiles = async (files) => {
        const results = await Promise.all(
            files.map(async (file) => {
                if (isExternalLink(file.link)) {
                    // Assume external links are valid, bypass HEAD check
                    return file;
                }

                try {
                    const response = await fetch(file.link, { method: 'HEAD' });
                    if (response.ok && response.headers.get('Content-Type') !== 'text/html') {
                        return file;
                    }
                } catch (error) {
                    console.error(`Error checking file: ${file.link}`, error);
                }
                return null;
            })
        );
        setAvailableFiles(results.filter(Boolean));
    };

    useEffect(() => {
        const currentProduct = productsData.find(prod => prod["SKU"] === activeTab);
        if (currentProduct) {
            const files = getProductFiles(currentProduct);
            checkFiles(files);
        }
    }, [activeTab, productsData]);

    return (
        <>
            <Helmet>
                <title>{product.Nome ? `${product.Nome} | SAVEENERGY` : "SAVEENERGY | Lighting Lifestyle"}</title>
                <meta name="description" content={product.Descricao || "A Save Energy é uma marca brasileira referência em tecnologia LED, oferecendo soluções de iluminação inovadoras. Explore o melhor da iluminação com a gente!"} />
                <meta property="og:title" content={product.Nome ? `${product.Nome} | SAVEENERGY` : "SAVEENERGY | Lighting Lifestyle"} />
                <meta property="og:description" content={product.Descricao || "Iluminação inovadora e sustentável com a Save Energy."} />
                <meta property="og:image" content={productImages.length > 0 ? productImages[0] : "/default-image.jpg"} />
                <meta property="og:url" content={window.location.href} />
                <meta name="twitter:title" content={product.Nome ? `${product.Nome} | SAVEENERGY` : "SAVEENERGY | Lighting Lifestyle"} />
                <meta name="twitter:description" content={product.Descricao || "Iluminação inovadora e sustentável com a Save Energy."} />
                <meta name="twitter:image" content={productImages.length > 0 ? productImages[0] : "/default-image.jpg"} />
            </Helmet>
            <NavBar />

            <span className="flex flex-col md:flex-row justify-between items-center py-4 px-4 md:px-20 text-lg font-semibold text-white bg-[#3c1053]">
                <div>
                    <Link to="/produtos" className="hover:underline">
                        Produtos
                    </Link>
                    <span className="mx-1">/</span>
                    <Link to={`/categoria/${product.Categoria}`} className="hover:underline">
                        <span>{product.Categoria}</span>
                    </Link>
                    <span className="mx-1">/</span>
                    <span>{product.SKU}</span>
                </div>
                <SearchInput />
            </span>

            <div className="w-full flex flex-col justify-center items-center py-8 mb-16">
                <h2 className='font-bold text-2xl md:text-3xl text-left mb-8 text-[#890083] ml-4 md:ml-0'>{product.Nome}</h2>

                {/* Tabs Section */}
                <div className="w-full max-w-[1300px] mb-4">
                    <div className="flex border-b border-gray-200">
                        {Object.keys(groupedProducts)
                            .slice(0, 9) // Limit to the first 6 keys
                            .map((groupKey) => {
                                const [sku] = groupKey.split("|");

                                return (
                                    <button
                                        key={groupKey}
                                        className={`text-lg px-4 py-2 ${activeTab === groupKey
                                            ? "border-b-2 border-purple-900 text-purple-900"
                                            : "text-gray-600"
                                            }`}
                                        onClick={() => {
                                            setActiveTab(groupKey);
                                            handleProductClick(sku);
                                        }}
                                    >
                                        {sku}
                                    </button>
                                );
                            })}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-2 w-full max-w-[1300px]">
                    <div className='px-4 md:px-0'>
                        <ProdImageCard product={{ ...product, imgs: productImages }} />
                    </div>
                    {/* Product Details Table */}
                    <div className="text-left text-[#3d1053] text-md md:text-lg px-4">


                        <table className="w-full text-left border border-neutral-300 shadow-md">
                            <tbody>
                                {[{
                                    label: "SKU",
                                    value: product.SKU
                                }, {
                                    label: "Ângulo",
                                    value: product.Angulo
                                }, {
                                    label: "Base",
                                    value: product.Base
                                }, {
                                    label: "Cor",
                                    value: product.Cor
                                }, {
                                    label: "Temperatura de Cor",
                                    value: product.TemperaturaCor
                                }, {
                                    label: "Eficiência Luminosa",
                                    value: product.EficienciaLuminosa
                                }, {
                                    label: "Fluxo Luminoso",
                                    value: product.FluxoLuminoso
                                }, {
                                    label: "Índice de Proteção",
                                    value: product.Protecao
                                }, {
                                    label: "IRC",
                                    value: product.IRC
                                }, {
                                    label: "Dimmer",
                                    value: product.Dimmer
                                }, {
                                    label: "Potência",
                                    value: product.Potencia
                                }, {
                                    label: "Tensão",
                                    value: product.Tensao
                                }, {
                                    label: "Tamanho do Produto (mm)",
                                    value: product.TamanhoProduto
                                }, {
                                    label: "Tamanho do Nicho (mm)",
                                    value: product.TamanhoNicho
                                }, {
                                    label: "Peso (g)",
                                    value: product.Peso
                                }, {
                                    label: "Garantia",
                                    value: product.Garantia
                                }, {
                                    label: "Registro Inmetro",
                                    value: product.RegInmetro
                                }, {
                                    label: "NCM",
                                    value: product.NCM
                                }, {
                                    label: "Código de Barras",
                                    value: product.CodigoDeBarras
                                }, {
                                    label: "Acompanha",
                                    value: product.Acompanha
                                },]
                                    .filter((item) => item.value) // Filter out items with empty/null/undefined values
                                    .map((item, index) => (
                                        <tr key={item.label} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                                            <td className="py-2 px-4 border border-neutral-200">{item.label}</td>
                                            <td className="py-2 px-4 font-semibold border border-neutral-200">{item.value}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>

                </div>

                {/* Product Files Section */}
                <div className="flex justify-center items-center my-8 mb-8 px-2 md:px-0">
                    <div className="md:w-[1300px] bg-white rounded-[32px] shadow border border-neutral-300 p-8">
                        <div className="grid grid-col-1 md:grid-cols-2 gap-8">
                            {availableFiles.map((productFile, index) => (
                                <div key={index} className="flex justify-between items-center border-b border-neutral-200 pb-4">
                                    {/* File name with link */}
                                    <div className="text-xl text-left font-semibold text-[#515151] md:pl-2 cursor-default">
                                        {productFile.name}
                                    </div>
                                    {/* Action icons */}
                                    <div className="flex space-x-8 md:pr-8">
                                        {/* View Icon */}
                                        <a
                                            href={productFile.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaEye className="h-6 w-6 text-[#890083] cursor-pointer hover:text-purple-900" title="Visualizar" />
                                        </a>
                                        {/* Download Icon */}
                                        <a
                                            href={productFile.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                        >
                                            <FaDownload className="h-6 w-6 text-[#890083] cursor-pointer hover:text-[#3c1053]" title="Download" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Suggested Products Section */}
                {suggestedProducts.length > 0 && (
                    <div className="w-full max-w-[1300px]">
                        <hr className="border border-gray-200 my-2 mb-8" />
                        <h3 className="text-purple-900 text-2xl font-bold mb-8">Produtos Sugeridos</h3>
                        <div className="grid grids-col-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4 md:px-0">
                            {suggestedProducts.map((suggestedProduct) => (
                                <ProdutoCard
                                    key={suggestedProduct.SKU}
                                    product={{
                                        ...suggestedProduct,
                                        imgs: getFilePath(suggestedProduct),
                                    }}
                                    onClick={() => handleProductClick(suggestedProduct.SKU)}
                                />
                            ))}
                        </div>
                    </div>
                )}

            </div>

            <Footer />
        </>
    );
};

export default ProdutoDetalhes;