import React from "react";
import iconsData from "../assets/IconesProdutos.json";

const ProductIcons = ({ product }) => {
  
  const getIcons = () => {
    const icons = [];

    // Garantia
  const garantiaKey = product.Garantia?.toLowerCase().trim();
  if (iconsData.Garantia[garantiaKey]) {
    icons.push(iconsData.Garantia[garantiaKey]);
  }

    // TamanhoProduto
    const tamanhoProdutoKeys = Object.keys(iconsData.TamanhoProduto);
    for (const key of tamanhoProdutoKeys) {
      if (product.TamanhoProduto?.startsWith(key)) {
        icons.push(iconsData.TamanhoProduto[key]);
        break; // Exit loop once a match is found
      }
    }

    // Tensao
    if (iconsData.Tensao[product.Tensao]) {
      icons.push(iconsData.Tensao[product.Tensao]);
    }

    // Dimmer
    if (iconsData.Dimmer[product.Dimmer]) {
      icons.push(iconsData.Dimmer[product.Dimmer]);
    }

    // RegInmetro
    if (product.RegInmetro === "SIM") {
      icons.push(iconsData.RegInmetro.SIM);
    }

    // Protecao
    if (iconsData.Protecao[product.Protecao]) {
      icons.push(iconsData.Protecao[product.Protecao]);
    }

    // ALTO IRC
    if (product.IRC === "≥90" || product.IRC === "≥97") {
      const ircKey = product.IRC;
      if (iconsData.IRC[ircKey]) {
        icons.push(iconsData.IRC[ircKey]);
      }
    }

    // Proshield
    if (product.Proshield === "SIM") {
      icons.push(iconsData.Proshield.SIM);
    }

    return icons;
  };

  const icons = getIcons();

  return (
    <div className="flex flex-row justify-center flex-wrap gap-4 py-4">
      
      {icons.map((iconPath, index) => (
        <img
          key={index}
          src={iconPath}
          alt={`Icon ${index}`}
          className="w-20 h-20 md:w-28 md:h-28 object-contain"
        />
      ))}
    </div>
  );
};

export default ProductIcons;
