import React from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Institucional = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Conheça Nossa História | Save Energy</title>
        <meta name="description" content="Somos uma marca líder em tecnologia LED, oferecendo soluções de iluminação inovadoras para transformar o seu espaço. Descubra o poder da iluminação de ponta conosco." />
      </Helmet>
      <NavBar />
      {/* Hero Section */}
      <div className="w-full h-[83px] bg-[#3c1053] flex justify-center items-center">
        <h1 className="text-white text-3xl font-bold">{t('institucional.title')}</h1>
      </div>

      <div className="flex flex-col justify-center items-center py-8 md:py-16 bg-gray-50">
        {/* Sections */}
        <section className="flex flex-col md:flex-row gap-8 w-full max-w-[1300px]">
          {[
            { img: '/media/institucional/certificacao.webp', title: 'certifications', text: 'certificationsText' },
            { img: '/media/institucional/inovacao.webp', title: 'innovation', text: 'innovationText' }
          ].map((item, idx) => (
            <div key={idx} className="bg-white shadow-lg rounded-3xl overflow-hidden w-full md:w-1/2">
              <img src={item.img} alt={t(`institucional.${item.title}`)} className="w-full h-[220px] object-cover" />
              <div className="p-8">
                <h2 className="text-[#3c1053] text-2xl font-bold mb-4">{t(`institucional.${item.title}`)}</h2>
                <p className="text-gray-700 text-lg leading-relaxed">{t(`institucional.${item.text}`)}</p>
              </div>
            </div>
          ))}
        </section>

        <section className="w-full max-w-[1300px] mt-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { img: '/media/institucional/qualidade.webp', title: 'quality', text: 'qualityText' },
              { img: '/media/institucional/fabrica.webp', title: 'factory', text: 'factoryText' }
            ].map((item, idx) => (
              <div key={idx} className="bg-white shadow-lg rounded-3xl overflow-hidden">
                <img src={item.img} alt={t(`institucional.${item.title}`)} className="w-full h-[220px] object-cover" />
                <div className="p-8">
                  <h2 className="text-[#3c1053] text-2xl font-bold mb-4">{t(`institucional.${item.title}`)}</h2>
                  <p className="text-gray-700 text-lg leading-relaxed">{t(`institucional.${item.text}`)}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="w-full max-w-[1300px] mt-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              { img: '/media/institucional/proshield.webp', title: 'proshield', text: 'proshieldText' },
              { img: '/media/institucional/nossosProdutos.webp', title: 'ourProducts', text: 'ourProductsText' }
            ].map((item, idx) => (
              <div key={idx} className="bg-white shadow-lg rounded-3xl overflow-hidden">
                <img src={item.img} alt={t(`institucional.${item.title}`)} className="w-full h-[220px] object-cover" />
                <div className="p-8">
                  <h2 className="text-[#3c1053] text-2xl font-bold mb-4">{t(`institucional.${item.title}`)}</h2>
                  <p className="text-gray-700 text-lg leading-relaxed">{t(`institucional.${item.text}`)}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Brands Section */}
        <section className="w-full max-w-[1300px] mt-12">
          <h2 className="text-[#3c1053] text-[32px] font-bold mb-2">{t('institucional.brands')}</h2>
          <div className="flex justify-center items-center rounded-[32px] h-[150px] border-2 border-[#3c1053] mx-4">
            <div className="flex flex-col md:flex-row justify-center items-center md:items-end gap-4 md:gap-12">
              <img src='/media/marcas/90lighting.png' alt="90+ Lighting" />
              <img src='/media/marcas/sel-logo.png' alt="SEL Lighting" />
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Institucional;
