import React, { useState } from 'react';

const TabelaLuceLineare = () => {

    const [expanded, setExpanded] = useState(null);

    const toggleExpand = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    const products = [
        {
            name: 'AMALFI',
            cores: 'Branco | Preto',
            protecao: 'IP40',
            garantia: '5 anos',
            sobreporDimensao: '16 x 11mm',
            sobreporComprimento: '1m, 2m, 3m',
            sobreporPeso: '201g, 400g, 595g',
            embutirNicho: '21 x 11mm',
            embutirDimensao: '28 x 11mm',
            embutirComprimento: '1m, 2m, 3m',
            embutirPeso: '201g, 400g, 595g',
        },
        {
            name: 'MILANO',
            cores: 'Branco | Preto',
            protecao: 'IP40',
            garantia: '5 anos',
            sobreporDimensao: '53 x 20mm',
            sobreporComprimento: '1m, 2m, 3m',
            sobreporPeso: '501g, 1015g, 1520g',
            embutirNicho: '55 x 20mm',
            embutirDimensao: '62 x 20mm',
            embutirComprimento: '1m, 2m, 3m',
            embutirPeso: '501g, 1015g, 1520g',
        },
        {
            name: 'SARDEGNA',
            cores: 'Branco | Preto',
            protecao: 'IP40',
            garantia: '5 anos',
            sobreporDimensao: '30 x 13mm',
            sobreporComprimento: '1m , 2m , 3m',
            sobreporPeso: '256g , 510g , 760g',
            embutirNicho: '33 x 13mm',
            embutirDimensao: '40 x 13mm',
            embutirComprimento: '1m, 2m, 3m',
            embutirPeso: '256g, 510g, 760g',
        },
        {
            name: 'TRENTO',
            cores: 'Branco | Preto',
            protecao: 'IP40',
            garantia: '5 anos',
            sobreporDimensao: '13 x 11mm',
            sobreporComprimento: '1m, 2m, 3m',
            sobreporPeso: '650g, 1290g, 1930g',
            embutirNicho: '16 x 11mm',
            embutirDimensao: '40 x 11mm',
            embutirComprimento: '1m, 2m, 3m',
            embutirPeso: '650g, 1290g, 1930g',
        },
        {
            name: 'ROMA',
            cores: 'Branco | Preto',
            protecao: 'IP20',
            garantia: '5 anos',
            sobreporDimensao: '75 x 50mm',
            sobreporComprimento: '1m, 2m, 3m',
            sobreporPeso: '1230g, 1650g, 2450g',
            embutirNicho: '',
            embutirDimensao: '',
            embutirComprimento: '',
            embutirPeso: '',
        },
        {
            name: 'PORTO FINO',
            cores: 'Branco | Preto',
            protecao: 'IP40',
            garantia: '5 anos',
            sobreporDimensao: '16 x 16mm',
            sobreporComprimento: '1m, 2m, 3m',
            sobreporPeso: '180g, 360g, 530g',
            embutirNicho: '',
            embutirDimensao: '',
            embutirComprimento: '',
            embutirPeso: '',
        },
        {
            name: 'TORINO',
            cores: 'Branco | Preto',
            protecao: 'IP40',
            garantia: '5 anos',
            sobreporDimensao: '32 x 46mm',
            sobreporComprimento: '1m, 2m, 3m',
            sobreporPeso: '650g, 1290g, 1930g',
            embutirNicho: '',
            embutirDimensao: '',
            embutirComprimento: '',
            embutirPeso: '',
        },
    ];

    const getProductLink = (productName, variant) => {
        const links = {
            'AMALFI': { 'a': 'https://save.com.br/produtos/Perfil/SE-255.3288', 'b': 'https://save.com.br/produtos/Perfil/SE-255.3287' },
            'MILANO': { 'a': 'https://save.com.br/produtos/Perfil/SE-255.3276', 'b': 'https://save.com.br/produtos/Perfil/SE-255.3275' },
            'TRENTO': { 'a': 'https://save.com.br/produtos/Perfil/SE-255.3265', 'b': 'https://save.com.br/produtos/Perfil/SE-255.3263' },
            'PORTO FINO': { 'a': 'https://save.com.br/produtos/Perfil/SE-255.3311', 'b': 'https://save.com.br/produtos/Perfil/SE-255.3310' },
            'ROMA': { 'a': 'https://save.com.br/produtos/Perfil/SE-255.3299', 'b': 'https://save.com.br/produtos/Perfil/SE-255.3298' },
            'TORINO': { 'a': 'https://save.com.br/produtos/Perfil/SE-255.3305', 'b': 'https://save.com.br/produtos/Perfil/SE-255.3304' },
            'SARDEGNA': { 'a': 'https://save.com.br/produtos/Perfil/SE-255.3252', 'b': 'https://save.com.br/produtos/Perfil/SE-255.3251' },
        };
        return links[productName][variant] || '#';
    };

    const formatProductName = (name) => {
        if (name === 'PORTO FINO') {
            return 'PortoFino';
        }
        return name.charAt(0) + name.slice(1).toLowerCase();
    };

    return (
        <div className="w-full flex flex-col overflow-hidden">

            <div className="w-full">
                {/* Desktop Layout */}
                <div className="hidden md:flex flex-col">

                    <div className="w-full bg-[#3d1053] flex justify-between gap-2 py-4 md:py-8 px-20">
                        {products.map((product, index) => (
                            <div>
                                <div className="w-full flex justify-between">
                                    <div className='relative group'>
                                        <a href={getProductLink(product.name, 'a')} target="_blank" rel="noopener noreferrer">
                                            <img
                                                className="transition-opacity duration-300 ease-in-out group-hover:opacity-0"
                                                src={`/media/LuceLineare/${formatProductName(product.name)}-a.png`}
                                                alt={`${product.name} A`}
                                                style={{ height: '280px', width: 'auto', marginLeft: product.name === 'ROMA' ? '8px' : ''}}
                                            />
                                            <img
                                                className="absolute inset-0 transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
                                                src={`/media/LuceLineare/${formatProductName(product.name)}-a-lit.png`}
                                                alt={`${product.name} A Lit`}
                                                style={{ height: '280px', width: 'auto', marginLeft: product.name === 'ROMA' ? '8px' : ''}}
                                            />
                                        </a>
                                    </div>
                                    <div className='relative group'>
                                        <a href={getProductLink(product.name, 'b')} target="_blank" rel="noopener noreferrer">
                                            <img
                                                className="transition-opacity duration-300 ease-in-out group-hover:opacity-0"
                                                src={`/media/LuceLineare/${formatProductName(product.name)}-b.png`}
                                                alt={`${product.name} B`}
                                                style={{ height: '280px', width: 'auto', marginLeft: product.name === 'ROMA' ? '8px' : ''}}
                                            />
                                            <img
                                                className="absolute inset-0 transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100"
                                                src={`/media/LuceLineare/${formatProductName(product.name)}-b-lit.png`}
                                                alt={`${product.name} B Lit`}
                                                style={{ height: '280px', width: 'auto', marginLeft: product.name === 'ROMA' ? '8px' : ''}}
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <p className="text-xl font-bold text-white mt-4">{product.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="w-full bg-white text-neutral-800 mt-2 flex justify-center">
                        {products.map((product, index) => (
                            <div key={index} className="w-full flex flex-row items-start gap-8 py-8">
                                <div className="w-full flex flex-col items-center gap-2 text-sm md:text-base">
                                    <div>Cores: <strong>{product.cores}</strong></div>
                                    <div>Proteção: <strong>{product.protecao}</strong></div>
                                    <div>Garantia: <strong>{product.garantia}</strong></div>
                                    <div className='mt-4 font-bold text-[#3d1053]'>SOBREPOR</div>
                                    <div>Dimensão: <strong>{product.sobreporDimensao}</strong></div>
                                    <div>Comprimento: <strong>{product.sobreporComprimento}</strong></div>
                                    <div>Peso: <strong>{product.sobreporPeso}</strong></div>
                                    {product.embutirDimensao && (
                                        <>
                                            <div className='mt-4 font-bold text-[#3d1053]'>EMBUTIR</div>
                                            <div>Nicho: <strong>{product.embutirNicho}</strong></div>
                                            <div>Dimensão: <strong>{product.embutirDimensao}</strong></div>
                                            <div>Comprimento: <strong>{product.embutirComprimento}</strong></div>
                                            <div>Peso: <strong>{product.embutirPeso}</strong></div>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Mobile Layout */}
                <div className="md:hidden flex flex-col items-center bg-neutral-800 px-4 py-6">
                    {products.map((product, index) => (
                        <div key={index} className="w-full mb-4">
                            <button className="flex flex-col items-center w-full" onClick={() => toggleExpand(index)}>
                                <div className='flex flex-row justify-center gap-4'>
                                    <img className="w-32 h-auto" src={`/media/LuceLineare/${formatProductName(product.name)}-a.png`} alt={product.name} />
                                    <img className="w-32 h-auto" src={`/media/LuceLineare/${formatProductName(product.name)}-b.png`} alt={product.name} />
                                </div>
                                <div className='flex flex-row justify-bewteen items-center gap-2 border-2 border-white rounded-lg py-1 px-4 mt-4'>
                                    <img className='w-6 h-6' src='/svg/add.svg' />
                                    <p className="w-full text-lg font-bold text-white">{product.name}</p>
                                </div>
                            </button>
                            {expanded === index && (
                                <div className="bg-white text-neutral-800 p-4 rounded-lg mt-2">
                                    <div>Cores: <strong>{product.cores}</strong></div>
                                    <div>Proteção: <strong>{product.protecao}</strong></div>
                                    <div>Garantia: <strong>{product.garantia}</strong></div>
                                    <div className='mt-4 font-medium'>SOBREPOR</div>
                                    <div>Dimensão: <strong>{product.sobreporDimensao}</strong></div>
                                    <div>Comprimento: <strong>{product.sobreporComprimento}</strong></div>
                                    <div>Peso: <strong>{product.sobreporPeso}</strong></div>
                                    {product.embutirDimensao && (
                                        <>
                                            <div className='mt-4 font-medium'>EMBUTIR</div>
                                            <div>Nicho: <strong>{product.embutirNicho}</strong></div>
                                            <div>Dimensão: <strong>{product.embutirDimensao}</strong></div>
                                            <div>Comprimento: <strong>{product.embutirComprimento}</strong></div>
                                            <div>Peso: <strong>{product.embutirPeso}</strong></div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TabelaLuceLineare;