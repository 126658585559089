import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full flex flex-col md:flex-row justify-center items-center h-auto md:h-[640px]">
            {/* Background Image */}
            <div className="w-full h-full -z-20">
                <img
                    src={t('about.image')}
                    alt="Background Waves"
                    className="w-full h-full object-cover"
                />
            </div>
        </div>
    );
};

export default About;