import React from 'react';
import { Helmet } from "react-helmet-async";
import NavBar from '../components/NavBar';
import ImageSlider from '../components/Slider';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import ProductsSection from '../components/ProductsSection';
import VideoSection from '../components/VideoSection'
import Marcas from '../components/Marcas';
import About from '../components/About';

const Home = () => {
    return (
        <>
        <Helmet>
            <title>SAVEENERGY | Lighting Lifestyle</title>
            <meta name="description" content="A Save Energy é uma marca brasileira referência em tecnologia LED, oferecendo soluções de iluminação inovadoras. Explore o melhor da iluminação com a gente!" />
        </Helmet>
            <NavBar />
            <SearchBar />
            <ImageSlider />
            <About/>
            <ProductsSection/>
            <VideoSection/>
            <Marcas/>
            <Footer/>
        </>
    )
}

export default Home