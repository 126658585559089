import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
    const { t } = useTranslation();

  useEffect(() => {
    const preloadImages = () => {
      const images = [
        "/banners/Banner-1.webp",
        "/banners/Banner-2.webp",
        "/banners/Banner-3.webp",
        "/banners/Banner-4.webp",
        "/banners/Banner-5.webp",
        "/banners/Banner-6.webp",
      ];
      images.forEach((src) => {
        const link = document.createElement("link");
        link.rel = "preload";
        link.as = "image";
        link.href = src;
        document.head.appendChild(link);
      });
    };
    preloadImages();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
  }

  const images = [
    { src: t('slider.banner1'), link: "https://save.com.br/lucelineare" },
    { src: t('slider.banner2'), link: "https://instagram.com/saveenergylife" },
    { src: t('slider.banner3'), link: "https://save.com.br/categoria/Embutido" },
    { src: t('slider.banner4'), link: "https://casoca.com.br/saveenergy.html" },
    { src: t('slider.banner5'), link: "https://save.com.br/categoria/Fitas%20e%20Placas%20LED" },
    { src: t('slider.banner6'), link: "https://save.com.br/blog/" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div className="w-full mx-0 overflow-x-hidden -z-10" aria-hidden="false">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <a href={image.link}>
              <img
                src={image.src}
                srcSet={`${image.src} 320w, ${image.src} 480w, ${image.src} 800w`}
                sizes="(max-width: 320px) 280px, (max-width: 480px) 440px, 800px"
                alt={`Slide ${index + 1}`}
                aria-label="Slide"
                loading="lazy"
                className="w-full object-cover"
              />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;