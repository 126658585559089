import React from 'react';
import { useTranslation } from 'react-i18next';

const Marcas = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full max-w-[1438px] mx-auto flex flex-col items-center gap-8 my-12 px-4">
            {/* Title */}
            <div className="w-full flex justify-center items-center text-[#3d1053] text-2xl md:text-4xl font-bold text-center md:text-left">
                {t('marcas.title')}
            </div>

            {/* Content 90+ */}
            <div className="flex flex-col md:flex-row justify-between items-center border border-gray-200 rounded-[32px] shadow-lg overflow-hidden">
                {/* Image */}
                <div className="w-full md:w-1/2 h-auto hidden md:block">
                    <img
                        className="w-full h-full object-cover bg-[#3d1053] rounded-tr-[30px] md:rounded-tr-[0px] md:rounded-bl-[30px] md:rounded-tl-[30px]"
                        src="/media/marcas/90-img.webp"
                        alt="Image"
                    />
                </div>

                {/* Text */}
                <div className="w-full md:w-1/2 flex flex-col px-6 py-8">
                    <img
                        className="w-[180px] md:w-[261px] mb-6 mx-auto md:ml-4"
                        src="/media/marcas/90lighting.png"
                        alt="Logo"
                    />
                    <p className="mt-4 pl-6 text-base md:text-xl font-normal text-center md:text-left text-[#3d1053]">
                        {t('marcas.90plusDescription')}
                    </p>

                    <a href='https://90pluslighting.com/' target="_blank" rel="noopener noreferrer" className="mt-8 mx-auto md:mx-4 px-6 py-3 bg-[#3d1053] text-white text-base md:text-xl rounded-full">
                        {t('marcas.learnMore')}
                    </a>
                </div>
            </div>
            {/* Content SEL */}
            <div className="flex flex-col md:flex-row justify-between items-center border border-gray-200 rounded-[32px] shadow-lg overflow-hidden">

                {/* Text */}
                <div className="w-full md:w-1/2 flex flex-col px-6 py-8">
                    <img
                        className="w-[80px] md:w-[91px] mb-6 mx-auto md:ml-4"
                        src="/media/marcas/sel-logo.png"
                        alt="Logo"
                    />
                    <p className="mt-4 pl-6 text-base md:text-xl font-normal text-center md:text-left text-[#3d1053]">
                        {t('marcas.selDescription')}
                    </p>
                    <a href='/catalogos/SEL_2024.pdf' target="_blank" rel="noopener noreferrer" className="mt-8 mx-auto md:mx-4 px-6 py-3 bg-[#3d1053] text-white text-base md:text-xl rounded-full">
                        {t('marcas.learnMore')}
                    </a>
                </div>

                {/* Image */}
                <div className="w-full md:w-1/2 h-auto hidden md:block">
                    <img
                        className="w-full h-full object-cover bg-[#3d1053] rounded-tl-[30px] md:rounded-tl-[0px] md:rounded-br-[30px] md:rounded-tr-[30px]"
                        src="/media/marcas/sel-img.webp"
                        alt="Image"
                    />
                </div>
            </div>
        </div>
    );
};

export default Marcas;
