import React, { useState, useEffect } from 'react';

const CategoriaCard = ({ product, onClick }) => {
    const { imgs = [], title, Nome } = product;
    const [displayImage, setDisplayImage] = useState('/default-image.png');
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const findValidImage = async () => {
            for (const imgPath of imgs) {
                const img = new Image();
                img.src = imgPath;
                try {
                    await img.decode(); // Ensure image loads without error
                    setDisplayImage(imgPath); // Set the first valid image path
                    setLoading(false); // Set loading to false once an image loads
                    break;
                } catch (error) {
                    // Continue to the next path if there's an error
                }
            }
        };
        findValidImage();
    }, [imgs]);

    return (
        <div
            className="bg-white rounded-[30px] shadow-md border border-neutral-300 hover:transform hover:scale-105 transition-transform duration-300 flex flex-col items-center cursor-pointer"
            onClick={onClick}
        >
            {/* Conditional rendering: Show spinner if loading, else show image */}
            {loading ? (
                <div className="h-[300px] w-[300px] flex items-center justify-center">
                    <div className="w-12 h-12 border-4 border-gray-300 border-t-purple-700 rounded-full animate-spin"></div>
                </div>
            ) : (
                <img
                    className="h-[300px] w-[300px] rounded-tl-[30px] rounded-tr-[30px] object-cover"
                    src={displayImage}
                    alt={title || Nome}
                    loading="lazy"
                />
            )}
            <div className="text-center text-gray-600 text-lg font-medium p-4 uppercase leading-[1.3rem]">
                {title || Nome}
            </div>
        </div>
  )
}

export default CategoriaCard