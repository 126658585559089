import React from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const Suporte = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>Sugestões e Dúvidas sobre Produtos | Save Energy</title>
        <meta name="description" content=" Aqui você consegue nos enviar todas as suas dúvidas e sugestões sobre os nossos produtos. Nos envie uma mensagem agora mesmo!" />
      </Helmet>
      <NavBar />
      <main className="flex-1">
        {/* Header Section */}
        <div className="w-full h-[83px] bg-[#3c1053] flex justify-center items-center">
          <h1 className="text-white text-3xl font-bold">{t('suporte.title')}</h1>
        </div>

        {/* Content Section */}
        <div className="w-full flex flex-wrap justify-center items-center mt-8 px-4">
          {/* Left Section: Terms and Conditions */}
          <div className="w-full md:w-[666px] md:h-[265px] bg-white rounded-[32px] shadow border border-neutral-300 m-4 p-6">
            <h2 className="text-purple-900 text-2xl md:text-[32px] font-bold">
              {t('suporte.termsTitle')}
            </h2>
            <hr className="border-2 border-purple-900 my-2 mb-8" />
            <div className="flex flex-col space-y-4">
              <a
                href="./termoscondicoes"
                className="rounded-[32px] border-2 border-[#890083] hover:bg-[#890083] text-[#890083] hover:text-white text-lg md:text-xl p-2 font-semibold text-center"
              >
                {t('suporte.qualityPolicy')}
              </a>
              <a
                href="./termoscondicoes"
                className="rounded-[32px] border-2 border-[#890083] hover:bg-[#890083] text-[#890083] hover:text-white text-lg md:text-xl p-2 font-semibold text-center"
              >
                {t('suporte.warrantyTerms')}
              </a>
            </div>
          </div>

          {/* Right Section: Customer Service */}
          <div className="w-full md:w-[666px] bg-white rounded-[32px] shadow border border-neutral-300 m-4 p-6">
            <h2 className="text-purple-900 text-2xl md:text-[32px] font-bold">
              {t('suporte.customerService')}
            </h2>
            <hr className="border-2 border-purple-900 my-2 mb-4" />
            <div className="text-center space-y-4">
              <div className="text-[#890083] text-3xl md:text-4xl font-bold">
                <a href="tel:08007505592">0800 750 5592</a>
              </div>
              <div className="text-[#890083] text-xl md:text-2xl">
                <a href="mailto:sac@save.com.br">sac@save.com.br</a>
              </div>
              <div className='flex flex-row gap-2'>
                <a
                  href='https://conteudo.save.com.br/quero-falar-com-um-consultor'
                  target='_blank'
                  rel='noopener noreferrer'
                  className="w-full md:w-2/3 bg-[#890083] text-white rounded-[32px] text-lg md:text-2xl font-bold py-2 px-4 border-2 border-[#890083] hover:bg-white hover:text-[#890083]">
                  {t('suporte.retailer')}
                </a>
                <a
                  href="https://wa.me/message/SRD47WM6CUQOK1"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-2/3 bg-[#890083] text-white rounded-[32px] text-lg md:text-2xl font-bold py-2 px-4 border-2 border-[#890083] hover:bg-white hover:text-[#890083]">
                  {t('suporte.contactUs')}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="flex flex-wrap justify-center items-center mt-4">
        <div className="w-[1364px] bg-white rounded-[32px] shadow border border-neutral-300 mx-4 p-6">
          <h2 className="text-purple-900 text-2xl md:text-[32px] font-bold">
              {t('suporte.faq')}
            </h2>
            <hr className="border-2 border-purple-900 my-2 mb-4" />
            </div>
        </div>*/}
      </main>
      <Footer />
    </div>
  );
};

export default Suporte;
