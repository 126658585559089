import React from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';

const TermosCondicoes = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Termos e Condições de Garantia | Save Energy</title>
        <meta name="description" content="Saiba mais sobre os termos e condições de garantia dos nossos produtos e esclareça todas as suas dúvidas com a nossa equipe de suporte." />
      </Helmet>
      <NavBar />
      <div className="bg-purple-900 min-h-screen flex items-center justify-center p-4">
        <div className="flex flex-col gap-8 max-w-6xl w-full my-4 md:my-8">

          {/* Quality Policy Card */}
          <div className="bg-white p-4 md:p-16 rounded-lg shadow-lg flex-1 text-gray-700">
            <h1 className="text-3xl text-[#3d1053] font-bold text-center mb-6">
              {t('termos.titleQualityPolicy')}
            </h1>
            <p className="text-left text-md leading-relaxed">
              {t('termos.qualityPolicyText')}
            </p>
          </div>

          {/* Warranty Terms Card */}
          <div className="bg-white p-4 md:p-16 rounded-lg shadow-lg flex-1 text-gray-700">
            <h1 className="text-3xl text-[#3d1053] font-bold text-center mb-6">
              {t('termos.titleWarrantyTerms')}
            </h1>
            <p className="text-left text-md leading-relaxed mb-4">
              {t('termos.warrantyIntro')}
            </p>

            <h2 className="text-left text-xl font-semibold mt-4 mb-2">
              {t('termos.consumerTitle')}
            </h2>
            <p className="text-left text-md leading-relaxed mb-4">
              {t('termos.consumerText')}
            </p>

            <h2 className="text-left text-xl font-semibold mt-4 mb-2">
              {t('termos.retailerTitle')}
            </h2>
            <p className="text-left text-md leading-relaxed mb-4">
              {t('termos.retailerText')}
            </p>

            <ul className="text-left list-disc list-inside mb-8 space-y-2 text-md">
              {t('termos.warrantyList', { returnObjects: true }).map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>

            <p className="text-lg font-regular leading-relaxed">
              {t('termos.contactText')}
              <a
                className="font-bold"
                href="https://wa.me/message/SRD47WM6CUQOK1"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('termos.contactLink')}
              </a>.
            </p>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermosCondicoes;
