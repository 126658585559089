import React, { useState, useEffect } from 'react';

const ProdutoCard = ({ product, onClick }) => {
    const { imgs = [], Nome, ambientImagePath } = product;
    const [flipped, setFlipped] = useState(false);
    const [displayImage, setDisplayImage] = useState('/media/default-image.webp');
    const [ambientImageValid, setAmbientImageValid] = useState(false);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        // Check and set the main product image
        const findValidImage = async () => {
            for (const imgPath of imgs) {
                const img = new Image();
                img.src = imgPath;
                try {
                    await img.decode();
                    setDisplayImage(imgPath);
                    break;
                } catch {
                    // Ignore invalid images
                }
            }
        };

        // Check if Ambientação image is valid
        const checkAmbientImage = async () => {
            if (ambientImagePath) {
                const img = new Image();
                img.src = ambientImagePath;
                try {
                    await img.decode();
                    setAmbientImageValid(true);
                } catch {
                    setAmbientImageValid(false);
                }
            }
        };

        const loadImages = async () => {
            await Promise.all([findValidImage(), checkAmbientImage()]);
            setLoading(false); // Turn off loading once images are verified
        };

        loadImages();
    }, [imgs, ambientImagePath]);

    return (
        <div
            className={`relative bg-white rounded-[30px] shadow-md border border-neutral-300 cursor-pointer hover:scale-105 transition-transform duration-300`}
            onClick={onClick}
            onMouseEnter={() => setFlipped(true)}
            onMouseLeave={() => setFlipped(false)}
        >
            {loading ? (
                // Show loading spinner
                <div className="flex justify-center items-center h-[300px] w-full">
                    <div className="w-12 h-12 border-4 border-gray-300 border-t-purple-700 rounded-full animate-spin"></div>
                </div>
            ) : flipped && ambientImageValid ? (
                // Show Ambientação
                <div className="absolute inset-0 rounded-[30px] overflow-hidden">
                    <img
                        className="h-full w-full object-cover"
                        src={ambientImagePath}
                        alt="Ambientação"
                    />
                </div>
            ) : (
                // Show default product image
                <>
                    <img
                        className="h-[300px] w-full rounded-tl-[30px] rounded-tr-[30px] object-cover"
                        src={displayImage}
                        alt={Nome}
                    />
                    <div className="text-center text-gray-600 text-lg font-medium p-4 uppercase leading-[1.3rem]">
                        {Nome}
                    </div>
                </>
            )}
        </div>
    );
};

export default ProdutoCard;
